import React from "react";
export default () => (
  <div
    style={{
      width: "160px",
      height: "36px",
      color: "black"
    }}
  >
    Word Cloud API
  </div>
);
